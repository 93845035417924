<script setup lang="ts">
import type { Component } from 'vue'
import { computed, markRaw, onMounted } from 'vue'
import { useRoute } from 'vue-router'
import DefaultLayout from '@/layouts/Default.vue'

const route = useRoute()
const layouts: { [key: string]: Component } = {
  default: DefaultLayout,
}
const routeLayout = computed(() => route.meta.layout || 'default')
const layout = computed(() => markRaw(layouts[routeLayout.value] || layouts.default))

onMounted(() => {
  window.setTimeout(() => {
    document.body.setAttribute('loaded', '1')
  }, 800)
})
</script>

<template>
  <div
    class="wrapper"
  >
    <component :is="layout">
      <router-view />
    </component>
  </div>
</template>

<style lang="scss" scoped>
.wrapper {
  height: 100vh;
  transition: all 400ms;
  scroll-behavior: smooth;
  color: #333333;
}
</style>
