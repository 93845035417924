import { createApp } from 'vue'

import router from './router'
import App from './App.vue'
import pinia from '@/modules/pinia'

import '@/assets/styles/index.scss'

const app = createApp(App)

app
  .use(router)
  .use(pinia)
  .mount('#app')
