import { createRouter, createWebHistory } from 'vue-router'
import {
  Home,
} from '../pages'

const routes = [
  {
    name: 'home',
    path: '/',
    component: Home,
    meta: {
      layout: 'default',
    },
  },
  // default route when none of the above matches
  {
    path: '/:pathMatch(.*)',
    redirect: {
      name: 'home',
    },
  },
]

const history = createWebHistory()
const router = createRouter({ history, routes })

// scroll to top and init page's title when route changes
router.beforeEach(() => {
  window.scrollTo(0, 0)
})

export default router
