<script setup lang="ts">
import { ref } from 'vue'
import AppHeader from '@/components/AppHeader.vue'

const headerScrolled = ref(false)
const header = ref()
const active = ref('')
const pageContainer = ref()

// eslint-disable-next-line ts/no-explicit-any
const handleScroll = function (e: any) {
  const sectionSizes: Record<string, number> = {
    presentation: Math.max(window.innerHeight, document.getElementById('presentation')!.clientHeight),
    campaign: Math.max(window.innerHeight, document.getElementById('campagne')!.clientHeight),
    candidates: Math.max(window.innerHeight, document.getElementById('candidats')!.clientHeight),
    events: Math.max(window.innerHeight, document.getElementById('evenements')!.clientHeight),
  }

  if (e.target.scrollTop <= sectionSizes.presentation - 70) {
    active.value = 'presentation'
  }
  if (e.target.scrollTop > sectionSizes.presentation - 70) {
    active.value = 'campagne'
  }
  if (e.target.scrollTop > sectionSizes.presentation + sectionSizes.campaign - 70) {
    active.value = 'candidats'
  }
  if (e.target.scrollTop > sectionSizes.presentation + sectionSizes.campaign + sectionSizes.candidates - 70) {
    active.value = 'evenements'
  }
  headerScrolled.value = e.target.scrollTop > 0
}

function setActive(newActive: string) {
  const sectionSizes: Record<string, number> = {
    presentation: 0,
    campagne: Math.max(window.innerHeight, document.getElementById('presentation')!.clientHeight),
    candidats: Math.max(window.innerHeight, document.getElementById('presentation')!.clientHeight) + Math.max(window.innerHeight, document.getElementById('campagne')!.clientHeight),
    evenements: Math.max(window.innerHeight, document.getElementById('presentation')!.clientHeight) + Math.max(window.innerHeight, document.getElementById('campagne')!.clientHeight) + Math.max(window.innerHeight, document.getElementById('candidats')!.clientHeight),
  }
  active.value = newActive
  pageContainer.value.scrollTo(0, sectionSizes[active.value])
}
</script>

<template>
  <main
    id="app__container"
  >
    <AppHeader
      ref="header"
      :active="active"
      :scrolled="headerScrolled"
      @navigate="($event: string) => setActive($event)"
    />
    <div
      ref="pageContainer"
      class="page__container"
      @scroll.passive="handleScroll"
    >
      <div class="page">
        <slot />
      </div>
    </div>
  </main>
</template>

<style lang="scss" scoped>
#app__container {
  position: relative;
  margin: auto;
  height: 100vh;
  overflow: hidden;
  .page__container {
    width: 100%;
    position: relative;
    margin: auto auto;
    overflow-y: auto;
    height: calc(100vh - 70px);
    margin-top: 70px;
    scroll-snap-type: y mandatory;
    scroll-behavior: smooth;
  }
  .page {
    width: 100%;
    position: relative;
    margin: auto;
    padding: 0px;
    box-sizing: border-box;
  }
}
</style>
